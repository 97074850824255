import * as React from 'react';

import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import MainLayout from 'layouts/MainLayout';
import { graphql } from 'gatsby';
import { Subtitle, Title } from 'components/headers';
import { useOfferItems } from 'hooks';

const PrivacyPolicy = ({ data: { others } }: any) => {
  const items = useOfferItems();
  const theme = useTheme();
  const matchesUpMD = useMediaQuery(theme.breakpoints.up('md'));

  const headerAlign = matchesUpMD ? 'left' : 'center';

  return (
    <MainLayout pageTitle={`${others.title} | NextGen`} offerItems={items} darkNavbar>
      <Container maxWidth="lg" sx={{ pt: { xs: 6, md: 8 }, pb: 7, textAlign: { xs: 'center', md: 'left' } }}>
        <Title align={headerAlign}>{others.title}</Title>
        <Subtitle align={headerAlign} maxWidth="lg">
          <Box dangerouslySetInnerHTML={{ __html: others.subtitle }} sx={boxStyle} />
        </Subtitle>
        <Box mt={-3} dangerouslySetInnerHTML={{ __html: others.content }} sx={boxStyle} />
      </Container>
    </MainLayout>
  );
};

export const boxStyle = {
  '& p': {
    mb: 2
  },
  '& h3': {
    mt: 5,
    mb: 2.5
  },
  '& a': { color: 'primary.main' },
  '& ul': {
    listStyleType: 'none',
    ml: { xs: 0, md: 2 },
    '& li': {
      mb: 1,
      textIndent: -5
    },
    '& li:before': {
      content: '"- "',
      textIndent: -5
    }
  }
};

export const query = graphql`
  query ($locale: String, $name: String) {
    others(name: { eq: $name }, locale: { eq: $locale }) {
      title
      subtitle
      content
    }
  }
`;

export default PrivacyPolicy;
